import { AxiosPromise, AxiosResponse } from 'axios';

interface ModelAttributs<T> {
  set(value: T): void;
  getAll(): T;
  get<K extends keyof T>(key: K): T[K];
}

interface Sync<T> {
  fetch(id: number): Promise<T>;
  save(data: T): AxiosPromise;
}

interface Events {
  on(eventName: string, callback: () => void): void;
  trigger(eventName: string): void;
}

interface HasId {
  id?: number;
}

export class Model<T extends HasId> {
  private isAuthorized: boolean = false;

  constructor(
    private attributes: ModelAttributs<T>,
    private events: Events,
    private sync: Sync<T>
  ) {}

  authorize(state: boolean): void {
    this.isAuthorized = state;
    this.events.trigger('isAuthorized');
  }

  authorized(): boolean {
    return this.isAuthorized;
  }

  on = this.events.on;
  trigger = this.events.trigger;
  get = this.attributes.get;

  set(update: T): void {
    this.attributes.set(update);
    this.events.trigger('change');
  }

  fetch(): void {
    this.sync.fetch(0).then((response): void => {
      this.authorize(true);
    });
  }

  save(): void {
    this.sync
      .save(this.attributes.getAll())
      .then((response: AxiosResponse): void => {
        this.trigger('save');
      })
      .catch(() => {
        this.trigger('error');
      });
  }
}
