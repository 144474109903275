import axios, { AxiosPromise } from 'axios';
interface HasId {
  id?: number;
}

interface FetchPayload {
  payload: {
    method: string;
    authorizationCode: string;
  };
}
export class ApiSync<T extends HasId> {
  private worker = new Worker('../../src/auth/worker.ts');
  private authorizationCode?: string;
  private resolves: { [k: string]: any } = {};
  private rejects: { [k: string]: any } = {};
  private ID = 0;

  constructor(public rootUrl: string) {
    const test_url = new URL(window.location.href);
    const params = new URLSearchParams(test_url.search);
    this.authorizationCode = params.get('code') ?? null;
    // this.authorizationCode = params.get('code') ?? 'dummy authorization code';
    this.worker.addEventListener(
      'message',
      (msg) => {
        const { id, err, payload } = msg.data;
        console.log('payload: ', msg);
        if (payload) {
          const resolve = this.resolves[id];
          if (resolve) {
            resolve(payload);
          }
        } else {
          // error condition
          const reject = this.rejects[id];
          if (reject) {
            if (err) {
              reject(err);
            } else {
              reject('Got nothing');
            }
          }
        }
        delete this.resolves[id];
        delete this.rejects[id];
      },
      false
    );
  }

  sendMsg(payload: FetchPayload): Promise<T> {
    const msgId = this.ID++;
    const msg = {
      id: msgId,
      payload: payload,
    };
    return new Promise((resolve, reject) => {
      this.resolves[msgId] = resolve;
      this.rejects[msgId] = reject;
      this.worker.postMessage(msg);
    });
  }

  fetch(id: number): Promise<T> {
    const pl: FetchPayload = {
      payload: {
        method: 'fetch',
        authorizationCode: this.authorizationCode,
      },
    };
    return this.sendMsg(pl);
  }

  save(data: T): AxiosPromise {
    const { id } = data;
    if (id) {
      return axios.put(`${this.rootUrl}/${id}`, data);
    } else {
      return axios.post(this.rootUrl, data);
    }
  }
}
